const experienceConstants = [
    {
        logo : require("../assets/novizit.png"),
        organisation : "Novizit",
        role : "Software Developer Intern",
        description : "Working as one of the core developer for Novizit real time application",
        languages : ["reactnative","tailwindcss"]
    },
    {
        logo : require("../assets/mvgrlogo.jpeg"),
        organisation : "MVGR",
        role : "MCC Tutor",
        description : "Tutored and mentored 200+ students as one of the core member of Maharaja Coding Club",
        languages : ["c","cpp","dsa","java","python"] 
    },
    {
        logo : require("../assets/codespeedy.png"),
        organisation : "CodeSpeedy",
        role : "Python Developer",
        description : "Worked on few projects as a Python developer in CodeSpeedy.",
        languages : ["python"] 
    }
]
export default experienceConstants;