import React from 'react'
import feedbackimg from "../assets/feedback-img.json";
import Lottie from 'lottie-react';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function Contact() {
  const [userFeedback,setUserFeedback] = useState("");
  const [userName,setUserName] = useState("");
  const msgTyped = (e)=>{
    setUserFeedback(e.target.value);
  }
  const nameTyped = (e)=>{
    setUserName(e.target.value);
    console.log(userName);
  }
  const submitFeedback = (e)=>{
    e.preventDefault();
    const username = userName;
    const userfeedback = userFeedback;
    setUserName("");
    setUserFeedback("");
    axios.post("http://localhost:3500/api/feedback/",{
      name : username,
      message : userfeedback
    }).then((response)=>{
      console.log(response);
      if(response.data.length > 1)
      {
        toast.error("Enter proper username & feedback!",{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      else if(response.data.length === 1)
      {
        if(response.data[0].msg === "Enter a valid name")
        {
          toast.warn("Enter a proper name!",{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          })
        }
        else if(response.data[0].msg === "Enter a valid message")
        {
          toast.warn("Enter a proper feedback!",{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          })
        }
      }
      else{
        toast.success("Thank you for your feedback❤️",{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    })
  }
  return (
    <div id='Contact' className='w-full h-[60dvh] flex-col items-center justify-center bg-yellow-500 p-4'>
      <h1 className='m-auto text-center my-4 text-4xl font-poppins500'>Feedback form :)</h1>
        <div className='w-[35dvw] h-[35dvh] bg-white shadow-black shadow-2xl flex items-center justify-center mx-auto'>
          <form className="w-full max-w-sm">
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                  Full Name
                </label>
              </div>
              <div className="md:w-2/3">
                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="text" placeholder="John Doe" name='name' value={userName} onChange={nameTyped}/>
              </div>
            </div>
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-password">
                  Message
                </label>
              </div>
              <div className="md:w-2/3">
                <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-black leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-password" type="text" placeholder="Your words matter a lot!" name='message' value={userFeedback} onChange={msgTyped}/>
              </div>
            </div>
            <div className="md:flex md:items-center">
              <div className="md:w-1/3"></div>
              <div className="md:w-2/3">
                <button className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit" onClick={submitFeedback}>
                  Submit
                </button>
              </div>
            </div>
          </form>
          <div className='w-[30dvw] absolute right-0 left-[38dvw] mx-auto'>
            <Lottie
              animationData={feedbackimg}
            />
          </div>
        </div>
      </div>
  )
}
