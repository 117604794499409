import React from 'react';
import bgabout from "../assets/bg-about.svg";
import projectsConstants from '../Constants/ProjectsConstants';
export default function Projects() {
  return (
    <div id='Projects' className='w-full h-screen flex items-center justify-center'>
      <img alt='bg' src={bgabout} className='absolute z-0 w-full overflow-y-hidden'/>
      <div className='z-[5] flex flex-wrap items-center justify-around w-[80dvw] h-[80dvh]'>
          {projectsConstants.map((project,index)=>(
            <div key={index} 
            className={`w-[20dvw] h-fit flex-col items-center justify-center z-[5] p-2 rounded-md ${project.isDark === true ? " bg-slate-700 text-white " : " bg-white text-black "}`}>
              <img className='cursor-pointer hover:scale-[0.95]' alt='projectpic' src={project.thumbnail}/>
              <div className='flex items-center justify-around w-[100%] h-fit flex-wrap'>
                {project.techstack.map((tech,index2)=>(
                  <img title={tech.alt} className='w-12 my-2' alt={tech.alt} src={tech.img} key={index2}/>
                ))}
              </div>
              <div className='w-[100%] font-poppins500 text-left'>
                <p className='w-fit'>Description : <span className={`w-fit ${project.isDark ? " text-white/50 " : " text-black/50 "}`}>{project.desc}</span></p>
              </div>
              <div className='w-[100%] font-poppins500 text-left'>
                <p className='w-fit'>Duration : <span className={`w-fit ${project.isDark ? " text-white/50 " : " text-black/50 "}`}>{project.duration}</span></p>
              </div>
              <a className='font-poppins500' href={project.link}>Project link🔗</a>
            </div>
          ))}
      </div>
    </div>
  )
}
