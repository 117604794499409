import React from 'react'
import footerData from "../Constants/FooterConstants";
export default function Footer() {
  return (
    <div className='bg-black w-full h-fit p-4 flex-col items-center justify-center'>
      <div className='text-white/70 m-auto flex items-center justify-around w-[80dvw]'>
      {footerData.map((item, index) => (
        <div key={index}>
          <h2 className='text-xl'>{item.heading}</h2>
          {item.children.map((links, index2) => (
            <div key={index2} className="flex flex-col items-center justify-around h-fit text-white/50 underline">
              <a className='text-center' href={links.url}>{links.title}</a>
            </div>
          ))}
        </div>
      ))}
      </div>
    </div>
  )
}
