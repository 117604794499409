import React from 'react';
import Home from "./Home/Home";
import About from "./About/About";
import Skills from "./Skills/Skills";
import Projects from "./Projects/Projects";
import Experience from "./Experience/Experience";
import Contact from "./Contact/Contact";
import ScrollUp from './Components/ScrollUp';
import Footer from "./Components/Footer";
import Assistant from './Assistant/Assistant';
import { ToastContainer } from 'react-toastify';
export default function Landing() {
  return (
    <div className='scrollbar-hide'>
        <Home/>
        <About/>
        <Skills/>
        <Projects/>
        <Experience/>
        <Contact/>
        <ScrollUp/>
        <Footer/>
        <Assistant/>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          className={`fixed`}
        />
    </div>
  )
}
