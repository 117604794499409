import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import lappypic from "../assets/lappypic.png";
import bgabout from "../assets/bg-about.svg";
export default function About() {
  return (
    <div id='About' className='w-full h-screen flex items-center justify-center'>
      <img alt='bg' src={bgabout} className='absolute z-0 w-full overflow-y-hidden'/>
      <div className='w-[75dvw] flex items-center justify-between z-10 text-white'>
        <div className='w-[40dvw] h-[50dvh] flex flex-col items-center justify-center m-auto p-4'>
          <TypeAnimation
            sequence={[
              "I love to Sing 🎶",2000,
              "I love Chess ♟️",2000,
              "I love to Code 🖥️",2000,
              "I love to Explore 👨🏻‍💻",10000
            ]}
            repeat={Infinity}
            speed={35}
            style={{ fontSize: '3rem', textAlign: 'center' }}
            className='font-poppins500 m-2'
          />
          <p className='font-poppins500 text-left text-md'>
            I am a Computer Science and Engineering student at MVGR College of Engineering(A), Vizianagaram, India. I am passionate about technology and its potential to solve real-world problems. I am always eager to learn new technologies and apply them to innovative solutions.
          </p>
        </div>
        <div>
            <div className='bg-white shadow-inner shadow-black flex items-center justify-center rounded-full'>
              <img className='w-[30dvw] m-auto' alt='laptop' src={lappypic} />
            </div>
        </div>
      </div>
    </div>
  )
}
