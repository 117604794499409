import React from 'react'
import "./skills.css";
export default function Skills() {
  return (
    <div id='Skills' className='w-full h-[100%] flex flex-wrap items-center justify-center'>
      <div className='flex flex-wrap items-center justify-around w-[80dvw] h-[90dvh]'>
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-original.svg" />
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg" />
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" />
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original-wordmark.svg" />     
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg" />   
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg" />   
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original-wordmark.svg" />  
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg" />
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg" />
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg" />
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg" />
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original-wordmark.svg" />
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg" />
      <img alt='lang' className='icons ' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg" />
      <img alt='lang' className='icons' src='https://pagepro.co/blog/wp-content/uploads/2020/03/react-native-logo-884x1024.png'/>
      </div>
    </div>
  )
}
