import Landing from "./Landing";
function App() {
  return (
    <div className="scrollbar-hide">
      <Landing/>
    </div>
  );
}

export default App;
