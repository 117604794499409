const footerData = [
    {
        heading : "Web Development",
        children : [
            {
                title : "GeeksforGeeks🔗",
                url : "https://www.geeksforgeeks.org/web-development/"
            },
            {
                title : "W3Schools🔗",
                url : "https://www.w3schools.com/whatis/"
            },
            {
                title : "Udemy🔗",
                url : "https://www.udemy.com/course/the-complete-web-development-bootcamp/"
            }
        ]
    },
    {
        heading : "Machine Learning",
        children : [
            {
                title : "GeeksforGeeks🔗",
                url : "https://www.geeksforgeeks.org/machine-learning/"
            },
            {
                title : "W3Schools🔗",
                url : "https://www.w3schools.com/python/python_ml_getting_started.asp"
            },
            {
                title : "TutorialsPoint🔗",
                url : "https://www.tutorialspoint.com/machine_learning/index.htm"
            }
        ]
    },
    {
        heading : "Social Media",
        children : [
            {
                title : "Instagram🔗",
                url : "https://www.instagram.com/svssathvik7/"
            },
            {
                title : "Github🔗",
                url : "https://github.com/themelody77"
            },
            {
                title : "LinkedIn🔗",
                url : "https://www.linkedin.com/in/sathvik-svs-a76596230/"
            }
        ]
    }
]
export default footerData;