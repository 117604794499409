import React from 'react';
import {Link} from "react-scroll";
import {FaDoorClosed} from "react-icons/fa6";
import {FaDoorOpen} from "react-icons/fa6";
import { useState } from 'react';
export default function Navbar() {
  const [login,setLogin] = useState(false);
  const toggleDoor = ()=>{
    setLogin(!login);
  }
  return (
    <div className='z-10 top-0 left-0 right-0 mx-auto w-full  h-fit flex items-center justify-center bg-yellow-400'>
      <Link  className='font-poppins500 px-4  p-2 cursor-pointer m-2  text-black ' to='Home' spy={true} smooth={true} offset={0} duration={500}>Home</Link>
      <Link  className='font-poppins500 px-4  p-2 cursor-pointer m-2  text-black ' to='About' spy={true} smooth={true} offset={-20} duration={500}>About</Link>
      <Link  className='font-poppins500 px-4  p-2 cursor-pointer m-2  text-black ' to='Skills' spy={true} smooth={true} offset={-50} duration={500}>Skills</Link>
      <Link  className='font-poppins500 px-4  p-2 cursor-pointer m-2  text-black ' to='Projects' spy={true} smooth={true} offset={0} duration={500}>Projects</Link>
      <Link  className='font-poppins500 px-4  p-2 cursor-pointer m-2  text-black ' to='Experience' spy={true} smooth={true} offset={0} duration={500}>Experience</Link>
      <Link  className='font-poppins500 px-4  p-2 cursor-pointer m-2  text-black ' to='Contact' spy={true} smooth={true} offset={0} duration={500}>Contact</Link>
      <div className='absolute w-fit right-0 mx-2 cursor-pointer'>
        {login ? <FaDoorOpen onClick={toggleDoor} className='text-3xl' title='Logout'/> : <FaDoorClosed onClick={toggleDoor} className='text-3xl' title='Login'/>}
      </div>
    </div>
  )
}
