import "./Home.css";
import Sun from "../assets/Sun.json";
import Lottie from "lottie-react";
import Navbar from '../Navigation/Navigation';
export default function Home() {
  return (
    <div id='Home' className='w-full h-screen'>
        <Navbar/>
        <img id='mountain-img' className='w-[75dvw] absolute left-0 right-0 m-auto top-0 bottom-0' alt='mountain' src='https://static.vecteezy.com/system/resources/previews/026/757/661/non_2x/ice-mountain-with-ai-generated-free-png.png'/>
        <h1 className='left-0 right-[32dvw] m-auto top-0 bottom-[20dvh] absolute text-8xl w-fit h-fit welcome-text text-black'>Hello</h1>
        <h1 className='welcome-text2 right-0 left-[18dvw] m-auto top-[10dvh] bottom-0 absolute text-6xl w-fit h-fit text-black'>Glad to have you!</h1>
        <div className="w-40 absolute top-0 bottom-[30dvh] left-[25dvw] right-0 m-auto h-fit">
          <Lottie animationData={Sun}/>
        </div>
    </div>
  )
}
