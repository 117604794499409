import React from 'react'
import experienceConstants from '../Constants/ExperienceConstants';
import { useState } from 'react';
export default function Experience() {
  const [index,setIndex] = useState(0);
  const nextCard = ()=>{
    setIndex(index+1);
  }
  const prevCard = ()=>{
    setIndex(index-1);
  }
  return (
    <div id='Experience' className='w-full h-screen flex items-center justify-center'>
      <div className='w-[fit] h-[fit] flex items-center justify-center'>
        <h1 className={`font-poppins500 text-5xl -rotate-90 w-fit h-fit`}>-Experience-</h1>
      </div>
      <div className='w-[60dvw] h-[70dvh] flex flex-col items-center justify-around'>
        <div id='card' className='w-[70dvw] h-[65dvh] flex flex-col items-center justify-around rounded-lg'>
            <div className='w-[25dvw] h-fit p-2 bg-slate-700 rounded-2xl'>
                <img alt={experienceConstants[index].organisation} className='w-[10dvw] mx-auto' src={experienceConstants[index].logo}/>
                <div className="px-6 py-4 text-center">
                  <div className="font-bold text-xl mb-2 text-white">{experienceConstants[index].role}</div>
                  <p className="text-white/80 text-base">
                    {experienceConstants[index].description}
                  </p>
                </div>
                <div className='w-full mx-auto text-center'>
                  {experienceConstants[index].languages.map((lang,index2)=>(
                    <span key={index2} className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2'>#{lang}</span>
                  ))}
                </div>
            </div>
            <div className='w-[60dvw] flex items-center justify-around'>
              <button onClick={prevCard} disabled={index===0} className={`w-[10dvw] py-2 bg-black text-white ${index === 0 ? " bg-black/50 " : " bg-black "}`}>Previous</button>
              <button disabled={index===experienceConstants.length-1} onClick={nextCard} className={`w-[10dvw] py-2 text-white ${index === experienceConstants.length-1 ? " bg-black/50 " : " bg-black "}`}>Next</button>
            </div>
        </div>
      </div>
    </div>
  )
}
