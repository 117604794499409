const projectsConstants = [
    {
        title : "Portfolio",
        thumbnail : require("../assets/portfolio.png"),
        techstack : [
            {
                img : require("../assets/html.png"),
                alt : "HTML"
            },
            {
                img : require("../assets/css.png"),
                alt : "CSS"
            },
            {
                img : require("../assets/javascript.png"),
                alt : "JS"
            }
        ],
        desc : "My personal portfolio developed using basic web tech stack!",
        duration : "1 month",
        link : "https://themelody77.github.io/myportfolio/",
        isDark : false
    },
    {
        title : "HungerHelp",
        thumbnail : require("../assets/HungerHelp.png"),
        techstack : [
            {
                img : require("../assets/html.png"),
                alt : "HTML"
            },
            {
                img : require("../assets/css.png"),
                alt : "CSS"
            },
            {
                img : require("../assets/javascript.png"),
                alt : "JS"
            },
            {
                img : require("../assets/nodejs.png"),
                alt : "Nodejs"
            },
            {
                img : require("../assets/python.png"),
                alt : "Python"
            },
            {
                img : require("../assets/mongo-db.png"),
                alt : "MongoDB"
            },
            {
                img : require("../assets/git.png"),
                alt : "Git"
            }
        ],
        desc : "A socially relevant project that aims at efficient food redistribution. A wide variety of technologies like Machine Learning, Ejs have been used.",
        duration : "4 months",
        link : "https://sathvik-web.onrender.com/",
        isDark : true
    },
    {
        title : "β-portfolio",
        thumbnail : require("../assets/Beta-portfolio.png"),
        techstack : [
            {
                img : require("../assets/react.png"),
                alt : "React"
            },
            {
                img : require("../assets/tailwind.png"),
                alt : "Tailwind css"
            },
            {
                img : require("../assets/nodejs.png"),
                alt : "Nodejs"
            },
            {
                img : require("../assets/python.png"),
                alt : "Python"
            },
            {
                img : require("../assets/mongo-db.png"),
                alt : "MongoDB"
            },
            {
                img : require("../assets/git.png"),
                alt : "Git"
            }
        ],
        desc : "My personal Beta portfolio which has advanced MERN stack and other features like AI-ML",
        duration : "UnderBuild",
        link : "https://sathvik-web.onrender.com/",
        isDark : true
    }
]
export default projectsConstants;