import axios from "axios";
import React, { useState, useRef } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaMinimize } from "react-icons/fa6";
export default function Assistant() {
  const [display, setDisplay] = useState(true);
  const [response, setReponse] = useState("Hello! How can i assist you?");
  const [userPrompt, setUserPrompt] = useState("");
  const [max, setMax] = useState(true);
  const assistantRef = useRef(null); // Create a ref for the Assistant div
  const userinputRef = useRef(null);

  const closeAssistant = () => {
    setDisplay(!display);
  };
  const resizeAssistant = () => {
    setMax(!max);
    if (max) {
      console.log(max);
      userinputRef.current.focus();
    }
  };

  const move = (e) => {
    const div = assistantRef.current; // Access the div using the ref
    if (div) {
      div.style.left = `${e.clientX - offsetX}px`;
      div.style.top = `${e.clientY - offsetY}px`;
    }
  };

  let offsetX, offsetY;

  const onMouseDown = (e) => {
    const div = assistantRef.current; // Access the div using the ref
    if (div) {
      offsetX = e.clientX - div.offsetLeft;
      offsetY = e.clientY - div.offsetTop;
      document.addEventListener("mousemove", move);
    }
  };

  const onMouseUp = () => {
    document.removeEventListener("mousemove", move);
  };
  const checkPrompt = (event) => {
    if (event.key === "Enter") {
      axios
        .post("http://localhost:3500/api/chat", {
          userPrompt: userPrompt,
        })
        .then((response) => {
          if(response.data.length > 1)
          {
            setReponse(response.data);
            console.log(response.data);
          }
          else{
            setReponse("Sorry I didn't get that :(");
          }
        });
      setUserPrompt("");
    }
  };
  const updatePrompt = (event) => {
    setUserPrompt(event.target.value);
    console.log(userPrompt);
  };
  return (
    <div ref={assistantRef} id="Assistant" className={`z-50 ${max ? " w-[35dvw] h-fit " : " w-[5dvw] h-[5dvh] "} bg-black/90 rounded-lg fixed top-[10dvh] m-2 text-white ${display === true ? " flex flex-col " : " hidden "}`} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
      <div className={`${max ? " justify-end w-[100%] " : " justify-center w-[5dvw] h-[5dvh]"
          } flex  h-fit items-center`}>
        <button onClick={resizeAssistant} className="w-fit h-fit">
          <FaMinimize className={`m-2 cursor-pointer hover:text-yellow-200`} />
        </button>
        <button onClick={closeAssistant} className="w-fit h-fit">
          <AiFillCloseCircle className="hover:text-red-600 m-2 cursor-pointer" />
        </button>
      </div>
      <div className={`${max ? " flex flex-col " : " hidden "} items-center justify-center p-2 font-poppins500 h-fit`}>
        <div className="flex items-center justify-center w-[100%]">
          <p className="w-[80%]"><span className={`text-yellow-500`}>Assistant : </span>{response}</p>
        </div>
        <div className="flex item-center justify-center w-[100%]">
          <p className="w-[10%] text-green-500">You : </p>
          <input
            onKeyDown={checkPrompt}
            type="text"
            ref={userinputRef}
            className='w-[70%] cursor-text before:content-["|"] bg-transparent outline-1 outline-dashed mb-4'
            autoFocus
            value={userPrompt}
            onChange={updatePrompt}
          />
        </div>
      </div>
    </div>
  );
}
